exports.components = {
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-adm-cotizador-admin-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/adm/cotizador-admin.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-adm-cotizador-admin-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-adm-editar-perfil-negocio-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/adm/editar-perfil-negocio.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-adm-editar-perfil-negocio-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-contacto-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/contacto.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-contacto-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-cotizacion-pedido-uuid-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-cotizacion-pedido-uuid-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-prod-[codigo]-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/prod/[codigo].js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-prod-[codigo]-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-productos-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/productos.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-productos-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-registro-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/registro.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-registro-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-sysadmin-deploys-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/sysadmin/deploys.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-sysadmin-deploys-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-ticket-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/pages/ticket.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-pages-ticket-js" */),
  "component---node-modules-acciondigital-tiendatruper-site-theme-src-templates-cotizador-js": () => import("./../../../node_modules/acciondigital-tiendatruper-site-theme/src/templates/cotizador.js" /* webpackChunkName: "component---node-modules-acciondigital-tiendatruper-site-theme-src-templates-cotizador-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adm-catalogo-impreso-[tipo]-js": () => import("./../../../src/pages/adm/catalogo-impreso/[tipo].js" /* webpackChunkName: "component---src-pages-adm-catalogo-impreso-[tipo]-js" */),
  "component---src-pages-adm-catalogos-impresos-js": () => import("./../../../src/pages/adm/catalogos-impresos.js" /* webpackChunkName: "component---src-pages-adm-catalogos-impresos-js" */),
  "component---src-pages-adm-cotizador-aquasol-admin-js": () => import("./../../../src/pages/adm/cotizador-aquasol-admin.js" /* webpackChunkName: "component---src-pages-adm-cotizador-aquasol-admin-js" */),
  "component---src-pages-cotizaciones-js": () => import("./../../../src/pages/cotizaciones.js" /* webpackChunkName: "component---src-pages-cotizaciones-js" */),
  "component---src-pages-cotizador-aquasol-js": () => import("./../../../src/pages/cotizador-aquasol.js" /* webpackChunkName: "component---src-pages-cotizador-aquasol-js" */),
  "component---src-pages-dev-productos-aquasol-js": () => import("./../../../src/pages/dev/productos-aquasol.js" /* webpackChunkName: "component---src-pages-dev-productos-aquasol-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */)
}

